.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Home-link {
  cursor: pointer;
}

.homePage {
  height: 86vh;
}

.home__box {
  margin-top: 0;
}

.home__strong {
  font-weight: 700;
}

.AA-logo {
  height: 120px;
  padding-bottom: 10px;
}

.TS-logo {
  height: 200px;
  padding-bottom: 10px;
  cursor: pointer;
}

.webmap {
  height: 92vh;
}

.gazetteer-page {
  height: 89vh;
}

.gazetteer-page__tab-panel {
  height: 88vh;
  overflow-y: auto;
}

.k-input,
.MuiInputBase-input {
  font-size: 0.875rem;
}

.form__component {
  width: 20vw;
}

.asd-ListItem {
  width: 29vw;
}

#StreetSearch {
  padding: 8px 8px;
}

.displayStreet {
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
}